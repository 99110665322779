/* eslint-disable
    wrap-iife,
    func-names,
    no-prototype-builtins,
*/

import domready from "domready"

// Simple fallback for link rel='preload' (supports IE9+)
const preloadSupported = () => {
  try {
    return document.createElement("link").relList.supports("preload")
  } catch (_) {
    return false
  }
}

if (!preloadSupported()) {
  domready(() => {
    document.querySelectorAll("link[rel='preload']").forEach((element) => {
      element.removeAttribute("onload")
      element.setAttribute("rel", "stylesheet")
    })
  })
}

// Add support to IE9+ for node.remove()
// see: https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove
// from: https://github.com/jserz/js_piece/blob/master/DOM/ChildNode/remove()/remove().md
;(function (arr) {
  arr.forEach((item) => {
    if (item.hasOwnProperty("remove")) {
      return
    }
    Object.defineProperty(item, "remove", {
      configurable: true,
      enumerable: true,
      writable: true,
      value: function remove() {
        if (this.parentNode !== null) {
          this.parentNode.removeChild(this)
        }
      },
    })
  })
})([Element.prototype, CharacterData.prototype, DocumentType.prototype])
