import $ from "jquery"
import { View } from "backbone"
/* eslint-disable
    class-methods-use-this,
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-return-assign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let TopbarStayInformedView
export default TopbarStayInformedView = function () {
  TopbarStayInformedView = class TopbarStayInformedView extends View {
    static initClass() {
      this.prototype.events = {
        "click .topbar-button.newsletter": "_subscribe",
        "click .topbar-button.facebook": "_facebook",
        "click .topbar-button.twitter": "_twitter",
        "click .topbar-button.linkedin": "_linkedin",
      }
    }

    initialize(options) {
      this._subscribe = this._subscribe.bind(this)
      this._facebook = this._facebook.bind(this)
      this._twitter = this._twitter.bind(this)
      this._linkedin = this._linkedin.bind(this)
      return super.initialize(options)
    }

    _subscribe(event) {
      event.preventDefault()
      return (window.location = $(event.currentTarget).find("a").attr("href"))
    }

    _facebook(event) {
      event.preventDefault()
      return (window.location = $(event.currentTarget).find("a").attr("href"))
    }

    _twitter(event) {
      event.preventDefault()
      return (window.location = $(event.currentTarget).find("a").attr("href"))
    }

    _linkedin(event) {
      event.preventDefault()
      return (window.location = $(event.currentTarget).find("a").attr("href"))
    }
  }

  TopbarStayInformedView.initClass()
  return TopbarStayInformedView
}
