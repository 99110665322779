/* eslint-disable no-console */

import Bugsnag from "@bugsnag/js"
import { currentUser } from "lib/currentUser"
import { isEmpty, get } from "lodash"

// A wrapper around bugsnag.
//
// If you want to track errors on a particular page, you should
// import this module and call ErrorTrack.start() during the page
// object initialization.
//
// Calling ErrorTrack.start() will automatically capture a lot of
// interesting stuff.
//
// See https://docs.bugsnag.com/platforms/browsers/js/
//

export class CustomLogger {
  static debug(message) {
    if (process.env.NODE_ENV === "development") {
      console.debug(`[bugsnag] ${message}`)
    }
  }

  static error(message) {
    console.error(`[bugsnag] ${message}`)
  }

  static info(message) {
    console.info(`[bugsnag] ${message}`)
  }

  static warn(message) {
    console.warn(`[bugsnag] ${message}`)
  }
}

export default class ErrorTrack {
  static start() {
    const configuration = {
      ...TC.bugsnagConfig,
      ...{
        logger: CustomLogger,
        onError: ErrorTrack.onError,
      },
    }

    try {
      Bugsnag.start(configuration)
    } catch (e) {
      console.warn("Bugsnag failed to start. Bad configuration?")
    }
  }

  /**
   * @param exception
   * @param {Object} details
   */
  static async notify(exception, details = {}) {
    // Set current user info
    const { id, email } = await currentUser()

    Bugsnag.notify(exception, (event) => {
      event.setUser(id, email)

      if (!isEmpty(details)) {
        event.addMetadata({ custom: details })
      }
    })
  }

  /**
   *
   * Add any logic here for updating an error event before passing it to Bugsnag.
   * More information:
   * https://docs.bugsnag.com/platforms/javascript/restify/customizing-error-reports/#updating-events-using-callbacks
   *
   * @param event. An instance of `@bugsnag/core/event`
   * @return {boolean}
   */
  static onError(event) {
    // Open up the Bugsnag Event object.
    // See https://docs.bugsnag.com/platforms/javascript/restify/customizing-error-reports/#the-event-object
    //
    // If the stacktrace on the originating error is from a file with the
    // `moz-extension` protocol, ignore the event by returning false - the error
    // originates from a Firefox extension and it's out of our control to fix
    const erroredFile = get(event, "errors.0.stacktrace.0.file")
    return !/^moz-extension/.test(erroredFile)
  }
}
