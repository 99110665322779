import React from "react"
import ReactDOM from "react-dom"

async function renderRelatedContent() {
  const { default: RelatedArticles } = await import("components/Content/RelatedArticles/Loader")

  const relatedArticlesEl = document.querySelector(".react-content-related-container")
  if (relatedArticlesEl !== null) {
    const { articleId } = relatedArticlesEl.dataset
    const relatedArticlesComponent = React.createElement(RelatedArticles, { articleId })
    ReactDOM.render(relatedArticlesComponent, relatedArticlesEl)
  }
}

export default function initRelatedContent() {
  const observedRelatedContentEl = document.querySelector(".react-content-related-container")

  // We don't want to render related content for content types without related content
  if (observedRelatedContentEl === null) {
    return
  }

  // `IntersectionObserver` feature detection for older browsers.
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#browser_compatibility
  if (typeof IntersectionObserver === "undefined") {
    renderRelatedContent()
    return
  }

  const relatedContentObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        renderRelatedContent()
        observer.disconnect()
      }
    })
  })
  relatedContentObserver.observe(observedRelatedContentEl)
}
