import React from "react"
import ReactDOM from "react-dom"

export default async function initPodcastFeeds(options) {
  const podcastFeedsEl = document.getElementById("podcast-listen-and-subscribe")

  if (podcastFeedsEl) {
    const { default: PodcastListenAndSubscribe } = await import(
      "containers/PodcastListenAndSubscribe"
    )

    const domContainer = document.createElement("div")
    const container = React.createElement(PodcastListenAndSubscribe, {
      subscriptionOptions: options,
    })

    podcastFeedsEl.parentNode.insertBefore(domContainer, podcastFeedsEl)
    ReactDOM.render(container, domContainer)
  }
}
