import PrintButton from "pages/content/PrintButton"
import React from "react"
import ReactDOM from "react-dom"

export default function initPrintButton() {
  const printButtonEl = document.createElement("span")
  const contentSocialDistributionEl = document.querySelector(".content-social-distribution")
  const printButtonComponent = React.createElement(PrintButton)

  contentSocialDistributionEl.appendChild(printButtonEl)
  ReactDOM.render(printButtonComponent, printButtonEl)
}
