/**
 * Utility function to test if localStorage is available (extracted from Modernizr).
 *
 * @see https://github.com/Modernizr/Modernizr/blob/v3.11.2/feature-detects/storage/localstorage.js
 * @return {boolean}
 */
export default function localStorageCheck() {
  const mod = "local-storage-availability-test"
  try {
    window.localStorage.setItem(mod, mod)
    window.localStorage.getItem(mod)
    window.localStorage.removeItem(mod)
    return true
  } catch (e) {
    return false
  }
}
