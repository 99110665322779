import React from "react"
import PropTypes from "prop-types"

const AdminAction = ({ path, icon, label, trackAction }) => {
  const iconElement = icon ? <i className={`icon-${icon}`} /> : null

  return (
    <li key={path}>
      <a href={path}>
        {iconElement}
        {label}
      </a>
    </li>
  )
}

AdminAction.propTypes = {
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  trackAction: PropTypes.string.isRequired,
}

AdminAction.defaultProps = {
  icon: undefined,
}

export default AdminAction
