import $ from "jquery"
import SocialButtonView from "views/social_button_view"

/**
 * @param {number|string} contentId
 */
function addShareCount(contentId) {
  $.ajax({
    url: `/share_counts/${contentId}`,
    success: (data) => {
      const { tweet_count, facebook_count, linked_in_count } = data

      $(".twitter-button").each((i, el) => new SocialButtonView({ el, count: tweet_count }))
      $(".facebook-button").each((i, el) => new SocialButtonView({ el, count: facebook_count }))
      $(".linkedin-button").each((i, el) => new SocialButtonView({ el, count: linked_in_count }))
    },
  })
}

/**
 * @param {number|string} contentId
 */
export default function initialize(contentId) {
  addShareCount(contentId)
}
