import PropTypes from "prop-types"
import React, { useEffect } from "react"
import ReactLazyLoad from "react-lazyload"

// The offset in pixels from the bottom of the viewport.
const OFFSET = 100

/**
 * This comonent calls the onView callback when it is lazily rendered.
 */
export function LazyWrapper({ children, onView }) {
  if (onView) {
    useEffect(onView, [])
  }
  return children
}

/**
 * This component wraps its children to make them lazily loadable.
 */
function LazyLoad({ children, onView }) {
  return (
    <ReactLazyLoad once offset={OFFSET}>
      <LazyWrapper onView={onView}>{children}</LazyWrapper>
    </ReactLazyLoad>
  )
}

LazyLoad.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  onView: PropTypes.func.isRequired,
}

export default LazyLoad
