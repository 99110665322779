import i18n from "lib/i18n"
import $ from "jquery"
import { defer, template } from "lodash"
import { View } from "backbone"
/* eslint-disable
    constructor-super,
    func-names,
    no-constant-condition,
    no-eval,
    no-multi-assign,
    no-multi-str,
    no-param-reassign,
    no-shadow,
    no-this-before-super,
    no-undef,
    no-underscore-dangle,
    no-unused-expressions,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// Represents a toggleable menu, with a consistent HTML interface:
// - an element (preferably an anchor), with class "menu-button"
// - an element (preferably an unordered list), with class "menu"
let TopbarToggleMenu
export default TopbarToggleMenu = (function () {
  TopbarToggleMenu = class TopbarToggleMenu extends View {
    static initClass() {
      this.prototype.menuClose = template(
        "\
<a class='menu-button-close' href='#'><%- i18n.t(\"top_bar.close_menu\") %></a>\
",
        { imports: { i18n } },
      )
    }

    initialize(options) {
      this.menuVisibilityClassName = `${options.selector.substring(1)}-open`
      this._openMenu = this._openMenu.bind(this)
      this._closeMenu = this._closeMenu.bind(this)
      this._documentClickHandler = this._documentClickHandler.bind(this)
      if (options == null) {
        options = {}
      }
      this.menuButtonOpen = this.$el.find(".menu-button-open")
      this.menuButtonOpen.show()
      this.menuButtonOpen.on("click", this._openMenu)

      return super.initialize(options)
    }

    _openMenu(event) {
      event.preventDefault()

      // We must lift the "state" of this object outside it's own
      // dom tree, because this dom tree can potentially be
      // completely replaced by "dynamic content".
      //
      // The reasons this is important are because if a user has
      // the menu open, and this state is not lifted out, it will
      // be lost when the topbar dom tree is replaced.
      //
      // This is bad for users, but it's especially bad for our
      // acceptance specs.
      //
      // It's absolutely terrible, but we will make it better I
      // promise!
      $("body").addClass(this.menuVisibilityClassName)

      // We rely on these document click events to close the menu
      defer(() => $(document).on("click", this._documentClickHandler))
    }

    _closeMenu(event) {
      if (event != null) {
        event.preventDefault()
      }

      // We must lift the "state" of this object outside it's own
      // dom tree, because this dom tree can potentially be
      // completely replaced by "dynamic content".
      //
      // The reasons this is important are because if a user has
      // the menu open, and this state is not lifted out, it will
      // be lost when the topbar dom tree is replaced.
      //
      // This is bad for users, but it's especially bad for our
      // acceptance specs.
      //
      // It's absolutely terrible, but we will make it better I
      // promise!
      $("body").removeClass(this.menuVisibilityClassName)

      // We rely on these document click events to close the menu
      defer(() => $(document).off("click", this._documentClickHandler))
    }

    _documentClickHandler(event) {
      event.stopPropagation()
      return this._closeMenu()
    }
  }
  TopbarToggleMenu.initClass()
  return TopbarToggleMenu
})()
