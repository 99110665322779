/* eslint-disable import/prefer-default-export */
import Cookies from "js-cookie"

export const SESSION_ACTIVE_COOKIE_NAME = "tc_session_active"
export const SIGNED_IN_COOKIE_NAME = "tc_signed_in"

/**
 * @return {Boolean}
 */
export function isSessionActive() {
  return Cookies.get(SESSION_ACTIVE_COOKIE_NAME) === "true"
}

export function justSignedIn() {
  return Cookies.get(SIGNED_IN_COOKIE_NAME) === "true"
}

export function deleteSignedInCookie() {
  Cookies.remove(SIGNED_IN_COOKIE_NAME)
}
