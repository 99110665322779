import $ from "jquery"
import { debounce } from "lodash"

export default function trackScrolling() {
  // Scroll Tracking
  const pageLoadedAt = new Date()
  const articleEl = $("#article")

  if (articleEl.length > 0) {
    const articlePercentVerticalPosition = {}
    const articleHeight = articleEl.height()
    const articleTop = articleEl.offset().top
    for (let percentOfArticle = 10; percentOfArticle <= 100; percentOfArticle += 10) {
      articlePercentVerticalPosition[percentOfArticle] =
        (articleHeight / 100) * percentOfArticle + articleTop
    }

    const reportScroll = () => {
      const scrollBottom = $(window).scrollTop() + $(window).height()
      return (() => {
        const result = []
        // eslint-disable-next-line guard-for-in,no-restricted-syntax
        for (const percent in articlePercentVerticalPosition) {
          const verticalPosition = articlePercentVerticalPosition[percent]
          if (scrollBottom > verticalPosition) {
            result.push(delete articlePercentVerticalPosition[percent])
          } else {
            result.push(undefined)
          }
        }
        return result
      })()
    }

    const throttleReportScroll = debounce(reportScroll, 50)
    $(window).scroll(throttleReportScroll)
    reportScroll()
  }
}
