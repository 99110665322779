import $ from "jquery"
import { View } from "backbone"

const clickHandler = (controlView, event) => {
  if (event) {
    const href = $(event.target).attr("href")
    if (href && href === "#") {
      event.preventDefault()
    }
  }

  const disabledWithCss = controlView.$el.hasClass("disabled")
  const disabledWithAttr = controlView.$el.attr("disabled")

  if (disabledWithCss) {
    console.log("DEPRECATED - Do not use css to disable controls")
  }

  if (!(disabledWithCss || disabledWithAttr)) {
    controlView.trigger("click", controlView, event)
  }
}

// A ControlView is the base class for control objects such as buttons and
// switches.
//
// A ControlView has methods for getting and setting the control state, for
// example `visible`, `disabled`, etc.
export default class ControlView extends View {
  initialize() {
    this.$el.on("click", (event) => clickHandler(this, event))
  }

  setVisible(value) {
    this.$el.toggle(value)
  }

  isVisible() {
    return this.$el.css("display") === "block"
  }

  state(value) {
    if (value) {
      this.turnOn()
    } else {
      this.turnOff()
    }
  }

  turnOn() {
    this.$el.addClass("on")
  }

  turnOff() {
    this.$el.removeClass("on")
  }

  hide() {
    this.setVisible(false)
  }

  show() {
    this.setVisible(true)
  }
}
