import $ from "jquery"

const toggleSidebar = () => {
  const openButton = $("#small-sidebar-open-button")
  const closeButton = $("#small-sidebar-close-button")
  const sidebar = $("#small-sidebar")
  const wrapper = $("#page-wrapper")

  if (sidebar.hasClass("visible")) {
    openButton.removeClass("visible")
    closeButton.removeClass("visible")
    sidebar.removeClass("visible")
    wrapper.removeClass("overlay-visible")
  } else {
    openButton.addClass("visible")
    closeButton.addClass("visible")
    sidebar.addClass("visible")
    wrapper.addClass("overlay-visible")
  }
}

export default async () => {
  $("#small-region-select-dropdown").on("change", (event) => {
    window.location = event.target.value
  })

  $("#small-sidebar-open-button").on("click", toggleSidebar)
  $("#small-sidebar-close-button").on("click", toggleSidebar)
}
