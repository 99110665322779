import Imagebox from "lib/imagebox"

/**
 * Initialize `figures.zoomable` selectors with the `Imagebox` component,
 * showing a "lightbox" with a larger version of the figure.
 *
 * Figures aren't zoomable on iPhones, as pinching to increase image size is a better
 * experience than opening a zoom modal.
 */
export default function initZoomableImages() {
  if (navigator.platform.indexOf("iPhone") === -1) {
    const zoomableFigures = document.querySelectorAll("figure.zoomable > a")
    zoomableFigures.forEach((figure) => new Imagebox(figure))
  }
}
