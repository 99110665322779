import $ from "jquery"
/* eslint-disable
    class-methods-use-this,
    func-names,
    no-multi-assign,
    no-shadow,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import imagesLoaded from "imagesloaded"

import SpinnerView from "views/spinner_view"

let Imagebox
// Call on the <figure>: new Imagebox($('figure.zoomable'))

export default Imagebox = class Imagebox {
  constructor(elements) {
    this.showImagebox = this.showImagebox.bind(this)
    $(elements)
      .append('<div class="enlarge_hint"></div>')
      .click((event) => {
        event.preventDefault()
        return this.showImagebox($(event.currentTarget)[0].href)
      })
  }

  loadImage(uri, callback) {
    const img = $(new Image())
    img.attr("src", uri)
    return imagesLoaded(img, () => {
      // The image has to be in the DOM for width & height to be set.
      img.appendTo("body").hide().data("dimensions", { x: img.width(), y: img.height() })
      return callback(img)
    })
  }

  showImagebox(uri) {
    $("div.imagebox").remove()
    $("<div />")
      .addClass("imagebox")
      .appendTo("body")
      .click(function () {
        return $(this).remove()
      })
    new SpinnerView({ centered: true, size: "50px" }).$el.appendTo($("body div.imagebox"))

    return this.loadImage(uri, (img) => {
      $("div.imagebox")
        .html(img.addClass("appearing").show())
        .click(function () {
          return $(window).unbind("resize", this.positionImagebox)
        })
      setTimeout(() => $("div.imagebox img").removeClass("appearing"), 10)
      this.positionImagebox()
      return $(window).resize(this.positionImagebox)
    })
  }

  positionImagebox() {
    const padding = 18 // $baseline * 3
    const img = $("div.imagebox img")
    const orig = img.data("dimensions")
    const page = {
      x: $(window).width() - padding - padding,
      y: $(window).height() - padding - padding,
    }
    const ratios = {
      orig: orig.x / orig.y,
      page: page.x / page.y,
    }
    let renderWidth = ratios.orig >= ratios.page ? page.x : (page.x * ratios.orig) / ratios.page
    if (renderWidth > orig.x) {
      renderWidth = orig.x
    }
    return img.css({
      width: `${renderWidth}px`,
      left: `${Math.max(padding, padding + (page.x - renderWidth) / 2)}px`,
      top: `${Math.max(padding, padding + (page.y - renderWidth / ratios.orig) / 2)}px`,
    })
  }
}
