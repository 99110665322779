import { defer, delay } from "lodash"
import { View } from "backbone"
/* eslint-disable
    consistent-return,
    func-names,
    no-multi-assign,
    no-shadow,
    no-undef,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// You can make a spinner easily with this!
// By default it will scale with the text of the element it's in
// but you can override it if you wish by passing in size like this:
//
//   new SpinnerView(size: '24px')
//
// You can set a custom foreground and background color:
//
//   new SpinnerView(foregroundColor: '#ccc', backgroundColor: '#aaa')
//
// You can also center the spinner within the parent element by
// passing in the centered option.
//
//   new SpinnerView(centered: true)
//
// ...or center it in the viewport using CSS position:fixed, by
// passing in the fixed option.
//
//   new SpinnerView(fixed: true)
//
// Hide and show methods support callbacks
//
//   @spinnerView.show(someAjax)
//

let SpinnerView
export default SpinnerView = (function () {
  SpinnerView = class SpinnerView extends View {
    static initClass() {
      this.prototype.className = "yet-another-spinner"
    }

    initialize(options) {
      super.initialize(options)
      if (options != null) {
        if (options.size) {
          this.$el.css("font-size", options.size)
        }
        if ((options.centered != null) === true) {
          this.$el.addClass("centered")
        }
        if ((options.fixed != null) === true) {
          this.$el.addClass("fixed")
        }
        if (options.backgroundColor && options.foregroundColor) {
          this.$el.css("border-color", options.backgroundColor)
          this.$el.css("border-top-color", options.foregroundColor)
        }
      }
      return this.render()
    }

    show(callback) {
      this.$el.show()

      if (this.visible()) {
        return typeof callback === "function" ? callback() : undefined
      }
      defer(() => this.$el.removeClass("hidden"))
      if (callback != null) {
        return delay(callback, 500)
      }
    }

    hide(callback) {
      if (!this.visible()) {
        if (typeof callback === "function") {
          callback()
        }
      } else {
        defer(() => this.$el.addClass("hidden"))
        if (callback != null) {
          delay(callback, 500)
        }
      }

      return delay(() => this.$el.hide(), 500)
    }

    visible() {
      return !this.$el.hasClass("hidden")
    }
  }
  SpinnerView.initClass()
  return SpinnerView
})()
