import { indexOf } from "lodash"
import { Model } from "backbone"
/* eslint-disable
    no-multi-assign,
    no-shadow,
    no-undef,
    no-unused-vars,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let User
export default User = class User extends Model {
  exists() {
    return this.get("type")
  }

  isEditor() {
    return this.get("type") === "Editor"
  }

  isAuthor() {
    return this.get("type") === "Author"
  }

  isConfirmedAuthor() {
    return this.get("author_profile_state") === "confirmed_expert"
  }

  hasAccessTo(feature) {
    if (indexOf(this.get("beta_features"), feature) !== -1) {
      return true
    }
    return false
  }

  getProfileNotificationsUrl() {
    return `/profiles/${this.get("slug")}/notifications`
  }
}

export const wrapInUserModel = (data) => Promise.resolve(new User(data))
