import $ from "jquery"

export default class Datawrapper {
  constructor(id) {
    this.id = id
    this.element = document.getElementById(id)

    window.addEventListener("message", this.receiveHeight.bind(this))
    window.addEventListener("resize", this.handleResize.bind(this))
  }

  receiveHeight(event) {
    const origin = event.origin || event.originalEvent.origin
    if (origin === "https://datawrapper.dwcdn.net") {
      const heights = event.data["datawrapper-height"]
      if (heights != null) {
        this.setHeight(heights[this.id])
      }
    }
  }

  setHeight(height) {
    if (!this.element) return

    // we only want the ideal height to ever "grow" to prevent datawrapper
    // charts from "jiggling" back and forth between two heights, this seems
    // to be a bug on datawrappers end when a chart contains hover tooltips,
    // but we can work around it to get a better user experience.
    //
    // the ideal height gets reset if the window width changes for whatever
    // reason (see handleResize)
    if (!this.iframeIdealHeight || this.iframeIdealHeight < height) {
      this.iframeIdealHeight = height
      this.element.style.height = this.iframeIdealHeight + "px"
    }
  }

  // this might seem a bit counter intuitive at first, but we only want to reset
  // the ideal height for datawrapper if the window width changed, because width
  // is the only thing that should change the layout (and height) of the chart/graph
  handleResize() {
    if (!this.windowWidth || this.windowWidth != window.innerWidth) {
      this.windowWidth = window.innerWidth
      this.iframeIdealHeight = undefined
    }
  }
}
