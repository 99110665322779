import $ from "jquery"
import Utils from "lib/tc/utils"
import pym from "pym.js"
/* eslint-disable
    no-multi-assign,
    no-param-reassign,
    no-return-assign,
    no-shadow,
    no-unused-vars,
    radix,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let Infographic

export default Infographic = class Infographic {
  constructor(id, url, opts) {
    // console.log(id, url, opts)
    this.scrollToChildPosMessage = this.scrollToChildPosMessage.bind(this)
    if (opts == null) {
      opts = {}
    }
    const originalIframe = $(`#${id}`)
    this.responsiveId = `responsive-${id}`
    this.url = url
    this.offset = parseInt(opts.offset || 0)
    this.height = parseInt(opts.height || 400)

    // remove the original iframe in favour of the enhanced iframe we're about to load
    originalIframe.hide()
    originalIframe.before(`<div id='${this.responsiveId}'></div>`)

    const pymParent = new pym.Parent(this.responsiveId, url, {})

    // set the height of Pym's replacement iframe, in case the infographic
    // doesn't run Pym to set it's parent div's height.
    // This now functions the same as if the child has `sendHeight('[INTEGER]')`
    // http://blog.apps.npr.org/pym.js/api/pym.js/1.0.0/module-pym.Parent.html#~_onHeightMessage
    pymParent.iframe.setAttribute("height", `${this.height}px`)

    pymParent.onMessage("saveScrollPosition", this.saveScrollPosition)
    pymParent.onMessage("revertScrollPosition", this.revertScrollPosition)
    pymParent.onMessage("scrollToChildPos", this.scrollToChildPosMessage)
  }

  scrollToChildPosMessage(message) {
    // Get the child container position using getBoundingClientRect + pageYOffset
    // via https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
    const iframePos =
      document.getElementById(this.responsiveId).getBoundingClientRect().top + window.pageYOffset
    const totalOffset = iframePos - this.offset + parseInt(message)
    return window.scrollTo(0, totalOffset)
  }

  saveScrollPosition() {
    return (this.lastScrollPosition = Utils.scrollYPosition())
  }

  revertScrollPosition() {
    return window.scrollTo(0, this.lastScrollPosition)
  }
}
