// We need to initialize common page elements,
// as this entrypoint isn't initialized by the main application.js
import "entry/common"

// Now, we import external libraries necessary to render the content page
import renderContentPromos from "pages/content/renderContentPromos"
import { initializeCommonPageElements } from "pages/common"
import initCustomMetaPixelEvents from "lib/metaPixel"
import initSocialMediaButtons from "pages/content/socialMediaButtons"
import initializeContentBody from "lib/content_body"
import trackScrolling from "pages/content/trackScrolling"
import initPodcastFeeds from "pages/content/podcastFeeds"
import initRepublishPromo from "pages/content/initRepublishPromo"
import initMobileToggles from "pages/content/mobileToggles"
import initPrintButton from "pages/content/initPrintButton"
import initRelatedContent from "pages/content/relatedContent"
import initCommentSection from "pages/content/commentSection"
import Infographics from "lib/tc/infographics"
import initZoomableImages from "pages/content/zoomableImages"
import initFlashNotices from "pages/content/initFlashNotices"
import localStorageCheck from "lib/localStorageCheck"
import getUserDataWhileRevalidate from "promos/getUserDataWhileRevalidate"
import rememberLastReadArticle from "lib/rememberLastReadArticle"
import GTMArticleCompleteEvent from "pages/content/GTMArticleCompleteEvent"

async function initialize() {
  const {
    comments_closed_at: commentsClosedAt,
    content_id: contentId,
    content_slug: contentSlug,
    promos_context: promosContext,
    subscriptionOptions,
  } = window.PAGE_CONFIG
  const contentBodyEl = document.querySelector("div.content-body")
  const storage = localStorageCheck() ? window.localStorage : undefined
  let user
  const userLoaded = new Promise((resolved) => {
    user = getUserDataWhileRevalidate(storage, resolved)
  })

  Infographics.init(contentBodyEl)

  initializeCommonPageElements()
  initPodcastFeeds(subscriptionOptions)
  initPrintButton()
  initSocialMediaButtons(contentId)
  rememberLastReadArticle(contentId)

  const signal = await renderContentPromos({
    ...promosContext,
    user: {
      donations: user.donations,
    },
  })

  userLoaded.then((user) => signal.next({ type: "donationsLoaded", donations: user.donations }))

  initCustomMetaPixelEvents(contentId)
  initRepublishPromo(contentId)
  initZoomableImages()
  trackScrolling()
  initMobileToggles()
  initRelatedContent()
  GTMArticleCompleteEvent()
  initCommentSection({ contentSlug, contentId, commentsClosedAt })
  initFlashNotices()
  if (contentBodyEl) initializeContentBody(contentBodyEl)
}

// Now, let's export the entrypoint initializer, so it's available for the
// `js_page` ERB yield block
TC.EntrypointInitializer = initialize
