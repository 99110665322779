import $ from "jquery"
import Minpubsub from "minpubsub"
import i18n from "lib/i18n"

/**
 * @param {string} contentSlug
 */
function closeComments(contentSlug) {
  const url = `/editorial/content/${contentSlug}/close_comments`
  return $.ajax({
    url,
    type: "PUT",
    success(data) {
      return Minpubsub.publish("comments:closed", [data.comments_closed_at])
    },
  })
}

/*
 * @param {string} contentSlug
 */
function openComments(contentSlug) {
  const url = `/editorial/content/${contentSlug}/open_comments`
  return $.ajax({
    url,
    type: "PUT",
    success(data) {
      return Minpubsub.publish("comments:opened", [data.comments_closed_at])
    },
  })
}

function updateCommentCount(commentsCount) {
  const commentsCountText =
    commentsCount !== 1
      ? i18n.t("comment.label.comment_plural")
      : i18n.t("comment.label.comment_singular")

  $(".comment-count").html(`${commentsCount} ${commentsCountText}`)
}

async function renderCommentSection(contentSlug, contentId, commentsClosedAt) {
  const { default: CommentsSectionView } = await import("views/comments_section_view")

  Minpubsub.subscribe("comments:count:updated", updateCommentCount)
  Minpubsub.subscribe("comments:close", () => closeComments(contentSlug))
  Minpubsub.subscribe("comments:open", () => openComments(contentSlug))

  // Comment section
  const commentsSectionView = new CommentsSectionView({
    commentsClosedAt,
    contentSlug,
    content_id: contentId,
  })
  commentsSectionView.$el.appendTo($("#comments-container"))

  updateCommentCount()
}

/**
 *
 * @param {string} contentSlug
 * @param {string} contentId
 * @param {string} commentsClosedAt
 */
export default function initCommentSection({ contentSlug, contentId, commentsClosedAt }) {
  const isCommentDeepLinked = /comment_[0-9]+/.test($(location).attr("hash"))

  if (typeof IntersectionObserver === "undefined" || isCommentDeepLinked) {
    renderCommentSection(contentSlug, contentId, commentsClosedAt)
  } else {
    const commentsIntersectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          renderCommentSection(contentSlug, contentId, commentsClosedAt)
          observer.disconnect()
        }
      })
    })

    const observedCommentsContainer = document.querySelector("#comments-container")

    if (observedCommentsContainer) {
      commentsIntersectionObserver.observe(observedCommentsContainer)
    }
  }
}
