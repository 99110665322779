import { format as dateFormat, formatDistanceToNow, formatISO } from "date-fns"
import i18n from "lib/i18n"

import currentLocale from "./currentLocale"

export default class TimeFormatter {
  /**
   * Formats the given date/time
   * @param {undefined, null, string, Date} time can be null, undefined an ISO 8601 string or a `Date` object
   * @param {string} format. Example: "tc_date_with_time"
   * @return {string}
   * @throws RangeError
   */
  static formatted(time, format = "tc_date_with_time") {
    const currentFormat = i18n.t(`time.formats.${format}`)

    const timeParam = time === null || time === undefined ? new Date() : new Date(time)

    if (currentFormat === undefined) {
      return formatISO(timeParam)
    }

    try {
      return dateFormat(timeParam, currentFormat, { locale: currentLocale.dateFnsLocale() })
    } catch (e) {
      if (e instanceof RangeError) {
        return formatISO(timeParam)
      } else {
        throw e
      }
    }
  }

  /**
   * Return the distance between the given date and now in words, localized.
   * @param {Date} dateTime
   * @param {Boolean} suffix. Adds suffix to the relative time, e.g.: "1 year ago"
   */
  static fromNow(dateTime, suffix = true) {
    return formatDistanceToNow(dateTime, {
      addSuffix: suffix,
      locale: currentLocale.dateFnsLocale(),
    })
  }
}
