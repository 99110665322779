// Configure the asset host for assets included within webpack (eg. <PodcastSubscriptionOption />)
// We configure a dynamic public path here because we want a different value
// on staging (my-staging-bucket.s3.aws.com) and production (cdn.theconversation.com).

// We set window.public_path in layouts/_js_head.html.erb, and webpack then uses
// this var when generating asset URLs.

// This line is in a separate entry point because if we were to add it in application.js,
// the ES6 import statements will be hoisted above it, and it will have no effect.

__webpack_public_path__ = window.public_path // eslint-disable-line camelcase
