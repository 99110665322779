/* eslint-disable import/prefer-default-export */

import fetch from "lib/fetch"
import ForgeryProtection from "lib/ForgeryProtection"
import { useState, useEffect } from "react"

const fetchQuery = async (query, variables) => {
  const { token } = await ForgeryProtection.CSRF

  const response = await fetch("/api/graphql", {
    method: "POST",
    headers: {
      "X-CSRF-Token": token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ query, variables }),
  })

  if (!response.ok) {
    return Promise.reject(new Error(`GraphQL fetch failed: (${response.status})`))
  }

  return response.json()
}

export const useQuery = (query, { variables } = {}) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(undefined)
  const [data, setData] = useState(undefined)

  useEffect(() => {
    fetchQuery(query, variables)
      .then((json) => {
        setData(json.data)
        setLoading(false)
      })
      .catch((fetchError) => {
        setError(fetchError)
        setLoading(false)
      })

    return () => {} // cleanup is a no-op for this effect (for now, may have caching later)
  }, [])

  return { loading, error, data }
}
