import Flash from "lib/flash"
import { currentUser } from "lib/currentUser"
import { wrapInUserModel } from "models/user"

export default async function initFlashNotices() {
  let params = new URLSearchParams(window.location.search)
  let message = params.get("notice")

  const user = await currentUser().then(wrapInUserModel)

  if (message && user.isEditor()) {
    Flash.now("notice", message)
  }
}
