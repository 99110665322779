import { template, throttle } from "lodash"
import { View } from "backbone"
import LowDrag from "lowdrag"
/* eslint-disable
    func-names,
    no-multi-assign,
    no-return-assign,
    no-shadow,
    no-undef,
    no-underscore-dangle,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let SuperSliderView

// TODO: Regular SliderView needs to be renamed,
// it's specifically for the history slider.
export default SuperSliderView = (function () {
  SuperSliderView = class SuperSliderView extends View {
    static initClass() {
      this.prototype.className = "super-slider-view"

      this.prototype.template = template(`\
<div class="super-slider-view-track">
  <div class="super-slider-view-slider">
    <div class="super-slider-view-slider-inner"></div>
  </div>
</div>\
`)

      this.prototype._throttledChange = throttle(function () {
        return this.trigger("change", this.percentage)
      }, 66)
    }

    initialize(options) {
      super.initialize(options)
      this.render()
      this._initTrack()
      return this._initSlider()
    }

    render() {
      super.render()
      return (this.el.innerHTML = this.template())
    }

    setValue(percentage) {
      const offsetLeft = (percentage * this.trackEl.width()) / 100
      return this.draggable.setValue(offsetLeft)
    }

    _initTrack() {
      this.trackEl = this.$el.find(".super-slider-view-track")
      return (this.cachedTrackWidth = this.trackEl.width())
    }

    _initSlider() {
      this.sliderEl = this.$el.find(".super-slider-view-slider")
      this.draggable = new LowDrag({
        element: this.sliderEl.get(0),
        axis: "x",
        containment: "parent",
      })

      this.sliderEl.get(0).addEventListener("drag", (event) => {
        const sliderLocation = event.detail.position.x
        const trackWidth = this.trackEl.width()
        this.percentage = (sliderLocation / trackWidth) * 100

        return this._throttledChange()
      })

      this.sliderEl.on("dragstart", () => this.trigger("changestart"))

      return this.sliderEl.on("dragfinish", () => this.trigger("changefinish"))
    }
  }
  SuperSliderView.initClass()
  return SuperSliderView // every 66ms is approx 15fps
})()
