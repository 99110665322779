/* eslint-disable import/prefer-default-export */

import fetch from "lib/fetch"
import { isSessionActive } from "lib/currentUser/session"

const CURRENT_USER_INFO_PATH = "/users/current.json"

class Cache {
  static get currentUser() {
    delete this.currentUser

    this.currentUser = fetch(CURRENT_USER_INFO_PATH).then((data) => data.json())

    return this.currentUser
  }
}

/**
 * Promise to fetch the current user.
 *
 * @returns {Promise}
 */
export function currentUser() {
  if (!isSessionActive()) {
    return Promise.resolve({})
  }

  return Cache.currentUser
}
