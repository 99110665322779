import $ from "jquery"
import { template } from "lodash"
import SuperLightboxView from "views/super_lightbox_view"

const republicationViewTemplate = template(`\
  <%= republishContent %>

  <footer class="button-row">
    <a class="button secondary close" href="#">Close</a>
  </footer>\
`)

export default class RepublicationView extends SuperLightboxView {
  initialize(options) {
    super.initialize()
    this.republishContent = options.republishContent
    this.copyAdvice = options.copyAdvice
    this.render()
    this.$el.find("section.advanced").hide()
    this.hideAllCodeBlocks()
    return this.$el.find("code.image").show()
  }

  render() {
    super.render()
    this.$innerEl.html(republicationViewTemplate({ republishContent: this.republishContent }))
    return this.$el.find(".copy-advice").html(this.copyAdvice)
  }

  showBasic(event) {
    this.$el.find(".tab.active").removeClass("active")
    $(event.currentTarget).addClass("active")
    this.$el.find("section.basic").show()
    return this.$el.find("section.advanced").hide()
  }

  showAdvanced(event) {
    this.$el.find(".tab.active").removeClass("active")
    $(event.currentTarget).addClass("active")
    this.$el.find("section.basic").hide()
    return this.$el.find("section.advanced").show()
  }

  showImageCode() {
    this.hideAllCodeBlocks()
    return this.$el.find("code.image").show()
  }

  showIframeCode() {
    this.hideAllCodeBlocks()
    return this.$el.find("code.iframe").show()
  }

  showScriptCode() {
    this.hideAllCodeBlocks()
    return this.$el.find("code.script").show()
  }

  hideAllCodeBlocks() {
    this.$el.find("code").hide()
  }
}

RepublicationView.prototype.events = {
  "click .button.close": "_leave",
  "click .tab.basic": "showBasic",
  "click .tab.advanced": "showAdvanced",
  "click input.image": "showImageCode",
  "click input.iframe": "showIframeCode",
  "click input.script": "showScriptCode",
}
