import { enAU, enCA, enGB, enUS, es, fr, frCA, id } from "date-fns/locale"

// Direct mapping with the server locale configuration
const LOCALES = {
  "en-AU": enAU,
  "en-CA": enCA,
  "en-GB": enGB,
  "en-GLOBAL": enAU,
  "en-IN": enAU,
  "en-NZ": enAU,
  "en-US": enUS,
  "en-ZA": enAU,
  "es-ES": es,
  "fr-CA": frCA,
  "fr-FR": fr,
  "id-ID": id,
  en: enAU,
  es,
  fr,
  id,
}

/**
 * Utility class to return the current locale defined by the user.
 * At the moment, we're relying on a global TC.locale variable.
 */
export default class currentLocale {
  /**
   * Returns the window.TC.locale variable value. If it's undefined, it will default to "en-AU"
   * TODO: we're relying on global TC.locale variable for now
   * @return {String | "en-AU"} locale code, with ISO 3166-1 alpha-2 sub-code. Example: "fr-FR"
   */
  static locale() {
    return (window.TC && window.TC.locale) || "en-AU"
  }

  /**
   * @return {Locale | enAU} a date-fns compatible locale
   */
  static dateFnsLocale() {
    return LOCALES[this.locale()] || enAU
  }
}
