/* eslint-disable import/prefer-default-export */

import { Subject } from "rxjs"

/**
 * Redirects to the given URL.
 *
 * @param {String} url The URL.
 */
export function redirectTo(url) {
  setTimeout(() => {
    window.location = url
  }, 0)
}

/**
 * The application event bus.
 *
 * This bus can be used to send events to any part of the application.
 */
export const eventBus = new Subject()
