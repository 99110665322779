import fetch from "lib/fetch"

const CSRF_REQUEST_PATH = "/csrf-token"

const metaTagValue = (name) => {
  const tag = document.querySelector(`meta[name=${name}]`)

  if (tag) {
    return tag.getAttribute("content")
  }

  return null
}

// A convenience class that allows asynchronous fetching of
// Rails CSRF tokens.
//
// The initial call to this method will start a request,
// subsequent requests will return either the promise for
// that in flight request, or immediately resolve the value
// if the request has already finished.
//
// Usage:
//
//    const { param, token } = await ForgeryProtection.CSRF;
//
// or
//
//    ForgeryProtection.CSRF.then(({ param, token}) => ... );
//
export default class ForgeryProtection {
  static get CSRF() {
    delete this.CSRF

    // Check to see if the page already has the CSRF token
    // so we don't unnecessarily fetch it.
    const preloadedCSRF = {
      param: metaTagValue("csrf-param"),
      token: metaTagValue("csrf-token"),
    }

    // If we've already got them, just resolve those values
    // immediately.
    if (preloadedCSRF.param && preloadedCSRF.token) {
      this.CSRF = Promise.resolve(preloadedCSRF)
    } else {
      this.CSRF = fetch(CSRF_REQUEST_PATH)
        .then((data) => data.json())
        .then(({ param, token }) => Promise.resolve({ param, token }))
    }

    return this.CSRF
  }
}
