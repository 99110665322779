import fetch from "lib/fetch"

export const emitEvent = (eventName, parameters = {}) => {
  if (!window.META_PIXEL_CONFIG) return

  const metaPixelId = window.META_PIXEL_CONFIG["id"]
  const metaPixelEnabled = window.META_PIXEL_CONFIG["enabled"]

  if (metaPixelId && metaPixelEnabled) {
    const customParameters = Object.keys(parameters).reduce(
      (s, key) => s + `&cd[${key}]=${parameters[key]}`,
      "",
    )
    fetch(`https://www.facebook.com/tr?id=${metaPixelId}&ev=${eventName}${customParameters}`)
  }
}

export default function initCustomMetaPixelEvents(contentId) {
  // After 15 seconds we emit a `AddToCard` event, this was changed from
  // the previous `TimeOnPage` and `Lead` event, as Benedictus (the ad agency) was having
  // trouble integrating it into their system (despite originally asking for it).
  if (contentId) {
    setTimeout(() => emitEvent("AddToCart"), 15000)
  }
}
