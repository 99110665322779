import { get } from "lodash"
import { push } from "./lib/googleTagManager/dataLayer"

const trackPromoEvent = (event, promo) => {
  const campaignName = get(promo, ["campaign", "name"])

  if (event && promo.type && promo.promoId && campaignName) {
    // dataLayer convention: event name will be snake cased. We'll have three different
    // events:
    // => promo_impression
    // => promo_dismiss
    // => promo_engagement
    push({
      event: `promo_${event.toLowerCase()}`,
      promo_id: promo.promoId,
      promo_type: promo.type,
      promo_campaign_name: campaignName,
      promo_category: promo.category,
    })
  }
}

export const trackImpression = (promo) => {
  trackPromoEvent("Impression", promo)
}

export const trackDismiss = (promo) => {
  trackPromoEvent("Dismiss", promo)
}

export const trackEngagement = (promo) => {
  trackPromoEvent("Engagement", promo)
}
