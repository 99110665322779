import cookieDomain from "lib/cookieDomain"
import Cookies from "js-cookie"

const COOKIE_EXPIRATION_DAYS = 30
const COOKIE_NAME = "tc_last_read_article"

/**
 * Persists the last read article ID into the `tc_last_read_article` cookie
 *
 * @param {string} articleId
 */
export default function rememberLastReadArticle(articleId) {
  Cookies.set(COOKIE_NAME, articleId, {
    domain: cookieDomain(),
    expires: COOKIE_EXPIRATION_DAYS,
    sameSite: "Lax",
  })
}
