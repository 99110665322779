import { pushArticleComplete } from "gtm_data_layer"

/**
 * Pushes a GTM event when the user finished reading the article.
 * We are going to use our related articles container selector as the observed element to push the event,
 * meaning that when users reach the related articles container, we'll push the GTM event.
 */
export default function GTMArticleCompleteEvent() {
  const observedRelatedContentEl = document.querySelector(".react-content-related-container")

  if (observedRelatedContentEl === null) {
    return
  }

  // `IntersectionObserver` feature detection for older browsers.
  // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#browser_compatibility
  if (typeof IntersectionObserver === "undefined") {
    return
  }

  const relatedContentObserver = new IntersectionObserver((entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        pushArticleComplete()
        observer.disconnect()
      }
    })
  })
  relatedContentObserver.observe(observedRelatedContentEl)
}
