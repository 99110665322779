const ping = (path) => {
  let xhr = false

  if (window.XMLHttpRequest) {
    xhr = new XMLHttpRequest()
  } else if (window.ActiveXObject) {
    // IE uses ActiveXObject...
    xhr = new ActiveXObject("Microsoft.XMLHTTP")
  }

  // If AJAX supported
  if (xhr !== false) {
    xhr.open("GET", path, true)
    xhr.send()
  }
}

export default ping
