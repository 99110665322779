/* eslint-disable max-len */
import ErrorTrack from "lib/error_track"

const BASE_URL = "https://theconversation.com"

/**
 * Generates promos href with UTM params, merging any query string in given `href`, except for UTM params.
 * UTM params from the given `href` will NOT be replaced.
 * @param {string} href Should be valid URL, absolute or relative. Examples: https://donate.theconversation.com/au?utm_campaign=AU+Donations, /au/newsletters/newsletter-1
 * @param {string} campaign Optional
 * @param {string} medium
 * @param {string} content
 */
/* eslint-enable */
export default function utmGenerator(href, campaign, medium, content) {
  try {
    const url = new URL(href, BASE_URL)

    if (!url.searchParams.has("utm_campaign") && campaign) {
      url.searchParams.set("utm_campaign", campaign)
    }

    if (!url.searchParams.has("utm_source")) {
      url.searchParams.set("utm_source", "theconversation.com")
    }

    if (!url.searchParams.has("utm_medium")) {
      url.searchParams.set("utm_medium", medium)
    }

    if (!url.searchParams.has("utm_content")) {
      url.searchParams.set("utm_content", content)
    }

    return url.toString()
  } catch (e) {
    ErrorTrack.notify(e)
    return href
  }
}
