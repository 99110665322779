import React from "react"
import PropTypes from "prop-types"
import I18n from "lib/i18n"
import AdminAction from "components/AdminAction"

const returnToPath = () => {
  if (window && window.location) {
    return `?return_to=${window.location.pathname}`
  }

  return ""
}

const DashboardNav = ({ user, role }) => {
  if (!["Editor", "Author"].includes(role)) {
    return null
  }

  const dashboardLink =
    role === "Editor" ? `/editorial/dashboards/${user.id}` : `/profiles/${user.id}/dashboard`

  return (
    <nav className="topbar-dashboard-link topbar-module">
      <ul className="topbar-item-list">
        <li>
          <a href={dashboardLink}>{I18n.t("top_bar.label.dashboard")}</a>
        </li>
      </ul>
    </nav>
  )
}

const UnauthenticatedActions = () => (
  <nav className="topbar-account-nav topbar-dropdown topbar-module">
    <ul className="topbar-item-list auth">
      <li className="author-signup">
        <a href="/become-an-author">{I18n.t("top_bar.label.author_sign_up")}</a>
      </li>
      <li className="reader-signup">
        <a href={`/sign_up${returnToPath()}`}>{I18n.t("top_bar.label.reader_sign_up")}</a>
      </li>
      <li className="reader-signin">
        <a href={`/sign_in${returnToPath()}`}>{I18n.t("top_bar.label.sign_in")}</a>
      </li>
    </ul>
  </nav>
)

const TopbarAdminShortcuts = ({ adminActions }) => {
  const firstAction = adminActions[0]

  if (!firstAction) {
    return null
  }

  return (
    <nav className="topbar-admin-shortcuts topbar-module">
      <ul className="topbar-item-list">
        <AdminAction {...firstAction} />
      </ul>
    </nav>
  )
}

const TopbarViewerActions = ({ adminActions, user, role }) => {
  if (user) {
    return (
      <React.Fragment>
        <DashboardNav user={user} role={role} />
        <TopbarAdminShortcuts adminActions={adminActions} />
      </React.Fragment>
    )
  }

  return <UnauthenticatedActions />
}

DashboardNav.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  role: PropTypes.string.isRequired,
}

DashboardNav.defaultProps = {
  user: undefined,
  adminActions: [],
  role: "Reader",
}

TopbarViewerActions.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  role: PropTypes.string,
  adminActions: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ),
}

TopbarAdminShortcuts.propTypes = {
  adminActions: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      icon: PropTypes.string,
    }),
  ).isRequired,
}

TopbarViewerActions.defaultProps = {
  user: undefined,
}

export default TopbarViewerActions
