import React, { Suspense } from "react"
import PropTypes from "prop-types"
import { useQuery } from "lib/graphql"
import TopbarViewerActions from "components/TopbarViewerActions"
import { isSessionActive } from "lib/currentUser/session"
import getSharedClassNameGenerator from "lib/shared_class_name_generator"
import { ThemeProvider } from "@theconversation/ui"

const AccountMenu = React.lazy(() => import("components/AccountMenu"))

export const VIEWER_QUERY = `
  query ($path: String!) {
    viewer {
      role
      user {
        id
        avatar
        name
        newsFeedCount
        commentNotificationCount
        allow {
          analytics
          moderateComments
        }
      }
    }
    adminActions (path: $path) { icon, label, path, trackAction }
  }
`

const TopbarDynamicContent = ({ path }) => {
  if (isSessionActive()) {
    const { loading, error, data } = useQuery(VIEWER_QUERY, {
      variables: { path },
    })

    if (loading) {
      return null
    }
    if (error) {
      return null
    }

    const adminActions = data.adminActions
    const role = data.viewer.role
    const user = data.viewer.user

    return (
      <Suspense fallback={null}>
        <ThemeProvider generateClassName={getSharedClassNameGenerator()}>
          <AccountMenu adminActions={adminActions} role={role} user={user} />
          <TopbarViewerActions adminActions={adminActions} role={role} user={user} />
        </ThemeProvider>
      </Suspense>
    )
  }

  return (
    <ThemeProvider generateClassName={getSharedClassNameGenerator()}>
      <TopbarViewerActions />
    </ThemeProvider>
  )
}

TopbarDynamicContent.propTypes = {
  path: PropTypes.string.isRequired,
}

export default TopbarDynamicContent
