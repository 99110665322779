import $ from "jquery"

function toggleTranslationSidebar(event) {
  event.preventDefault()
  $(".content-translations").toggleClass("open")
}

function toggleArticleSidebar(event) {
  event.preventDefault()
  $(".content-sidebar").toggleClass("open")
}

/**
 * Bind mobile toggleable element events to the content page
 */
export default function mobileToggles() {
  $(document).on(
    "click",
    ".content-translations .content-translations-toggle",
    toggleTranslationSidebar,
  )
  $(document).on("click", ".content-authors .content-authors-toggle", toggleArticleSidebar)
}
