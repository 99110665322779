import { currentUser } from "lib/currentUser"
import { deleteSignedInCookie, justSignedIn } from "lib/currentUser/session"
import { push } from "lib/googleTagManager/dataLayer"

/*
  See also: https://github.com/conversation/docs/tree/main/product/google-analytics
*/

export async function pushPageViewEvent() {
  push({ event: "page_view", page_type: window.GTM?.pageType })
}

export async function pushUserDetails() {
  const user = await currentUser()
  const userId = user?.id || ""

  push({
    event: "user_details",
    user_id: userId,
  })

  if (justSignedIn()) {
    push({
      // login is the recommended event name for GA4
      event: "login",
      user_id: userId,
    })

    deleteSignedInCookie()
  }

  if (window.GTM?.UserCreatedSignal) {
    push({
      // sign_up is the recommended event name for GA4
      event: "sign_up",
      user_id: userId,
    })
  }

  if (window.GTM?.AccountConfirmedSignal) {
    push({
      // Use user_account_confirmed to indicate the account was confirmed, and is
      // now eligible to log in. Can occur through email verification or SSO.
      event: "user_account_confirmed",
      user_id: userId,
    })
  }
}

export async function pushNewsletterSubscription() {
  if (!window.GTM?.NewsletterListSubscriptionEvent) {
    return
  }

  const user = await currentUser()
  const userId = user?.id || ""

  push({
    event: "newsletter_sub",
    user_id: userId,
    product_name: window.GTM.NewsletterListSubscriptionEvent.listName,
  })
}

export async function pushArticleData() {
  if (!window.GTM?.ArticleData) {
    return
  }

  push(window.GTM.ArticleData)
}

/**
 * Article complete event is pushed when the user reaches the end of the article.
 * It contains the same data as the article details event, but with a different event name.
 */
export async function pushArticleComplete() {
  if (!window.GTM?.ArticleData) {
    return
  }

  const articleData = window.GTM.ArticleData || {}
  const articleCompleteEvent = { ...articleData, event: "article_complete" }

  push(articleCompleteEvent)
}

export async function pushCustomDimensions() {
  if (!window.GTM?.CustomDimensions) {
    return
  }

  push(window.GTM.CustomDimensions)
}

Promise.allSettled([
  pushCustomDimensions(),
  pushUserDetails(),
  pushNewsletterSubscription(),
  pushArticleData(),
]).finally(() => {
  pushPageViewEvent()
})
