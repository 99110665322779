import $ from "jquery"
import i18n from "lib/i18n"
import RepublicationView from "views/republication_view"
import { eventBus } from "utils"

// Build a string containing device-specific advice for copying
function copyAdvice() {
  const iPhone = !navigator.platform.indexOf("iPhone")
  const mac = !navigator.platform.indexOf("Mac")
  const copyKey = mac ? "\u2318" : "Ctrl"
  if (iPhone) {
    return i18n.t("content.message.copying_text_on_mobile_device")
  }
  return i18n.t("content.message.key_combination_to_press_to_copy_text", {
    control_or_command_key: copyKey,
  })
}

function republish(contentId) {
  return $.ajax({
    url: `/share/${contentId}`,
    dataType: "text",
    success: (data) => {
      const republicationView = new RepublicationView({
        republishContent: data,
        copyAdvice: copyAdvice(),
      })
      return republicationView.$el.appendTo(document.body)
    },
  })
}

function bindRepublishStolenBodySelect() {
  $(document).on("click", "textarea.stolen-body", (event) => $(event.currentTarget).select())
}

export default function initRepublishPromo(contentId) {
  // Subscribe to promos event bus, so we can trigger the `republish` event
  // when the republish promo is clicked
  eventBus.subscribe((e) => {
    if (e.type === "republish") {
      republish(contentId)
    }
  })

  // When users select the stolen body on the republish lightbox, we want to select all text
  bindRepublishStolenBodySelect()
}
