/* eslint-disable
    no-cond-assign,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
export default {
  scrollYPosition() {
    return window.pageYOffset || document.documentElement.scrollTop
  },

  slugParser(slug) {
    let match
    if ((match = slug.match(/theconversation.com\/.*-(\d+)$/))) {
      return match[1]
    }
    return null
  },
}
