import Datawrapper from "lib/tc/datawrapper"
import Infographic from "lib/tc/infographic"

// TC.infographics is a utility which allows us to quickly identify, load and
// replace the existing infographics on a page.
//
// Usage:
//
//   Infographics = require("lib/tc/infographics")
//   Infographics.init(el)
//
// It also is the container for a global last scroll position used by
// the infographics to save and revert scroll positions.

const Infographics = {
  /**
   * @param {Element} page
   * @param {Object} opts
   */
  init(page, opts = {}) {
    const offset = opts.offset || 0
    const infographics = page.querySelectorAll("iframe.tc-infographic")
    const datawrappers = page.querySelectorAll("iframe.tc-infographic-datawrapper")

    infographics.forEach((infographic) => {
      const id = infographic.getAttribute("id")
      const src = infographic.getAttribute("src")
      const height = infographic.getAttribute("height")

      return new Infographic(id, src, { offset, height })
    })

    datawrappers.forEach((infographic) => {
      const id = infographic.getAttribute("id")

      return new Datawrapper(id)
    })
  },
}

export default Infographics
