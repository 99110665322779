import currentRegion from "../lib/currentRegion"
import fetch from "../lib/fetch"
import renderComponents from "./renderComponents"
import { consentStatus } from "../lib/didomi"

const PROMOS_URL = "/promos?regionCode=:regionCode"

/**
 * This function will update the promos client context based on user consent, listening to Didomi's consent changes.
 * @param signal the `@theconversation/promos-client`  placement engine signal object
 * @param context the current context
 */
const updatePromosEngineConsentContext = async (signal, context) => {
  const updateConsent = async () => {
    const consent = await consentStatus({
      vendorId: window.DIDOMI.THE_CONVERSATION_SDK_ID,
      purpose: window.DIDOMI.THE_CONVERSATION_PROMOS_PURPOSE,
    })
    signal.next({ type: "refresh", custom: { ...context, consent } })
  }

  await updateConsent()
  window.addEventListener("didomi:consent.changed", () => updateConsent())
}

/**
 * Fetches promos from the server, placing the list of candidate promos on the page.
 *
 * If the current region isn't available, we'll pass the `no-region` region to the server,
 * returning promos from the default region.
 *
 * @param {Object} context A custom context object added to placement engine's context.
 */
export default async function renderPromos(context = {}) {
  let regionCode
  try {
    regionCode = await currentRegion()
  } catch (e) {
    regionCode = "no-region"
  }
  const data = await fetch(PROMOS_URL.replace(":regionCode", regionCode))
  const candidatePromos = await data.json()
  const signal = renderComponents(candidatePromos, context)

  await updatePromosEngineConsentContext(signal, context)

  return signal
}
