import React, { Suspense } from "react"
import ReactDOM from "react-dom"

// This is a no-op intentionally, if something goes wrong we
// just render nothing for now.
const handleTwitterRenderError = () => {}

const LazyTweet = React.lazy(() => import("components/Tweet"))
const LazyInstagram = React.lazy(() => import("components/InstagramEmbed"))
const LazyTiktok = React.lazy(() => import("components/TiktokEmbed"))

function Tweet(props) {
  return (
    <Suspense fallback={null}>
      <LazyTweet {...props} />
    </Suspense>
  )
}

function Instagram(props) {
  return (
    <Suspense fallback={null}>
      <LazyInstagram {...props} />
    </Suspense>
  )
}

function Tiktok(props) {
  return (
    <Suspense fallback={null}>
      <LazyTiktok {...props} />
    </Suspense>
  )
}

const rehydrateComponents = (container, component, componentName, renderError) => {
  const nodes = Array.from(container.querySelectorAll(`[data-react-class=${componentName}]`))
  nodes.forEach((node) => {
    const props = JSON.parse(node.dataset.reactProps)
    const tweetComponent = React.createElement(component, { ...props, renderError })
    ReactDOM.render(tweetComponent, node)
  })
}

// Rehydrate dynamic content in a given content body
//
// Usage:
//
// import initializeContentBody from "lib/content_body";
//
// const node = document.querySelector("div.something");
// initializeContentBody(node);
//
export default (node) => {
  rehydrateComponents(node, Tweet, "Tweet", handleTwitterRenderError)
  rehydrateComponents(node, Instagram, "InstagramEmbed")
  rehydrateComponents(node, Tiktok, "TiktokEmbed")
}
