import fetch from "fetch-retry"
import unfetch from "unfetch"

const DEFAULT_RETRY_TIMES = 5 // Retry 5 times by default
const DEFAULT_RETRY_DELAY = 1000 // Wait for 1 second before each retry
const DEFAULT_RETRY_ON = [503] // Retry when encountering 503, standard fastly unavailable origin response

/**
 * Fetches the given URL and returns a promise that resolves to a response
 * object.
 *
 * @param {String} url The URL to fetch.
 * @param {Object} [options] The options object.
 * @returns {Promise<Response>} A promise that resolves to a response.
 * @example
 *
 * import fetch from "lib/fetch"
 *
 * fetch("http://example.org")
 *   .then(response => console.log(response))
 *   .catch(error => console.log("request failed", error))
 */
export default function customFetch(url, options = {}) {
  const currentOptions = {
    retries: DEFAULT_RETRY_TIMES,
    retryDelay: DEFAULT_RETRY_DELAY,
    retryOn: DEFAULT_RETRY_ON,
    ...options,
  }

  return fetch(unfetch)(url, currentOptions)
}
