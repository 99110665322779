import ChainedBackend from "i18next-chained-backend"
import i18next from "i18next"
import LocalStorageBackend from "i18next-localstorage-backend"
import localStorageCheck from "lib/localStorageCheck"
import ping from "lib/ping"
import XHRBackend from "i18next-xhr-backend"

// An abstraction and wrapper around i18next to allow
// us to provide access to translations via file local imports.
//
// If a locale is set on window, it will use that to key into
// the translations. Otherwise it will fall back to en_AU.
//
// If translations have not been loaded, it will fall back to
// key passthrough.
//
// Usage:
//
//   import i18n from "lib/i18n";
//
//   i18n.t("my.translation");
//
export default class I18n {
  static init(loadPath, locale, crossDomain, callback) {
    const backends = []

    if (localStorageCheck()) {
      backends.push(LocalStorageBackend)
    }

    backends.push(XHRBackend) // default

    i18next.use(ChainedBackend).init(
      {
        backend: {
          backends,
          backendOptions: [
            // array of options in order of backends above
            {
              versions: {
                [locale]: loadPath,
              },
            },
            {
              crossDomain,
              loadPath,
            },
          ],
        },
        lng: locale,
      },
      (err) => {
        if (err && err.length) {
          ping(`/no_js_locale?message=${err[0]}`)
        }

        try {
          callback()
        } catch (e) {
          ping(`/js_init_failure?message=${e}`)
          throw e
        }
      },
    )
  }

  static t(...args) {
    return i18next.t(...args) || args[0]
  }
}
