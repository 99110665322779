import { currentUser } from "lib/currentUser"
import { DEFAULT_USER_STATE } from "@theconversation/promos-client"

/**
 * Fetch and return user data relevant to promos.
 *
 * This method asynchronously fetches user details via currentUser() and stores them to local storage.
 * Meanwhile, it immediately returns the current value from local storage. This approach returns user
 * data immediately while guaranteeing that any changes will be propagated after two calls (ie. two
 * page loads).
 *
 * @param {Object} storage Reference to local storage
 * @param {Function} onUserChange Callback to be triggered when a new version of the user is received
 */
const getUserDataWhileRevalidate = (storage, onUserChange) => {
  // Fetch user and trigger callback with the updated details
  if (storage && onUserChange) {
    currentUser().then((tcUser) => {
      const initialUser = JSON.parse(storage.getItem("user")) || { ...DEFAULT_USER_STATE }
      const user = { ...initialUser, donations: tcUser.donations }
      onUserChange(user)
    })
  }

  // Fetch user details from localStorage
  return (storage && JSON.parse(storage.getItem("user"))) || DEFAULT_USER_STATE
}

export default getUserDataWhileRevalidate
