// Just a fair warning that this is a bit of an anti pattern,
// it almost guarantees that we'll end up requiring things
// that won't be used.
//
// We just can't really avoid it at the moment because the
// bulk of page initialization still rests on backbone, and
// therefore jquery's shoulders, which need to be preconfigured.
//
// Once we start cleaning up that stuff, we should aim to remove
// as much from this "common" file as possible.
//
// ...and please don't add to it :)

// only do this once!
import "lib/polyfill"

// Lazysizes provides lazy loading for responsive images.
import "lazysizes"

// We still have a couple of UJS things hanging around
import Rails from "rails-ujs"

// set up backbone and it's dependencies
import $ from "jquery"
import Backbone from "backbone"
import ForgeryProtection from "lib/ForgeryProtection"

Backbone.$ = $

// Ensure CSRF-Token is included in all jQuery ajax requests
//
// It's important to note that this has a known race condition
// where AJAX requests which require the CSRF token will potentially
// be missing them if the initial request to get the CSRF token
// has not completed yet.
ForgeryProtection.CSRF.then(({ token }) => {
  $.ajaxPrefilter((options, originalOptions, xhr) => {
    if (!options.crossDomain) {
      xhr.setRequestHeader("X-CSRF-Token", token)
    }
  })
})

// Must call this to make rails-ujs work,
Rails.start()
