import ReactDOM from "react-dom"
import { ThemeProvider } from "@theconversation/ui"
import { placementEngine } from "@theconversation/promos-client"
import { throttle } from "lodash"
import groupBy from "lodash/groupBy"
import React from "react"

import getSharedClassNameGenerator from "../lib/shared_class_name_generator"
import localStorageCheck from "../lib/localStorageCheck"
import Promo from "../components/Promo"

// Throttle refresh events to at most one per second.
const REFRESH_RATE = 1000

/**
 * Renders the given promos.
 *
 * @private
 * @param {Object} target The target element.
 * @param {Object} placementsMap A map from slot IDs to promos.
 * @param {Function} onClick The callback function called when a promo is
 * clicked.
 * @param {Function} onClose The callback function called when a promo is
 * closed.
 * @param {Function} onView The callback function called when a promo is viewed.
 * @returns The react element.
 */
function renderSlot(target, placementsMap, onClick, onClose, onView) {
  const id = parseInt(target.dataset.id, 10)
  const promos = placementsMap[id] || []
  const promoComponents = promos.map((promo) => (
    <Promo
      key={promo.promoId}
      promo={promo}
      placeholder={target.innerHTML}
      onClick={onClick}
      onClose={onClose}
      onView={onView}
    />
  ))

  ReactDOM.render(
    <ThemeProvider generateClassName={getSharedClassNameGenerator()}>
      {promoComponents}
    </ThemeProvider>,
    target,
  )
}

/**
 * Places the list of candidate promos on the page.
 *
 * @param {Array} candidatePromos The list of promos.
 * @param {Object} context A custom context object added to placement engine's context.
 */
export default function renderComponents(candidatePromos, context = {}) {
  let onRefresh
  const storage = localStorageCheck() ? window.localStorage : undefined

  // Grab the slots from the DOM.
  const slots = Array.from(document.getElementsByClassName("slot"))

  // Create the placement engine signal.
  const signal = placementEngine(storage, candidatePromos, context)

  // Throttle calls to onRefresh.
  //
  // Scrolling can generate a whole bunch of events, so we need to make sure we
  // don't handle too many of them and kill the browser.
  const handleRefresh = throttle(() => {
    if (onRefresh) {
      onRefresh()
    }
  }, REFRESH_RATE)

  // Listen for window events.
  window.addEventListener("resize", handleRefresh)
  window.addEventListener("scroll", handleRefresh, { passive: true })

  signal.subscribe((e) => {
    // Set the onRefresh callback.
    onRefresh = e.onRefresh

    // Group the promos by slotId to get the placements for each slot.
    const placementsMap = groupBy(e.promos, "slotId")

    // Render the slots.
    slots.forEach((slot) => renderSlot(slot, placementsMap, e.onClick, e.onClose, e.onView))
  })

  return signal
}
