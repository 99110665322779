/**
 * We are going to wait 4 seconds until Didomi is ready to be used, otherwise, we can assume it is taking too
 * long to be loaded.
 * Didomi JS is loaded through the https://sdk.privacy-center.org/, which uses Amazon CloudFront CDN.
 * At the moment of this writing, its base JS to use Didomi's JS API transferred gziped size is 3.6 KB, which
 * should take less than a second even on a slow connection.
 */
const DIDOMI_READY_TIMEOUT_IN_MILLISECONDS = 4000

/**
 * Check if the current user has given consent for a specific purpose and vendor.
 *
 * @param {String} vendorId The vendor ID to check. E.g.: `c:theconver-dUNGGyiP`
 * @param {String} purpose The purpose to check. E.g.: `theconver-AA8pWL4T`
 * @return {Promise<boolean>}
 * @fullfill {boolean} true if the user has given consent, false otherwise
 * @reject {Error} If the Didomi SDK is not ready after a timeout.
 */
export async function consentStatus({ vendorId, purpose }) {
  return new Promise((resolve, reject) => {
    // If Didomi is disabled, we're considering that users have given consent
    if (!window.DIDOMI.ENABLED) {
      resolve(true)
    }

    const didomiOnReady = window.didomiOnReady || []

    /**
     * The `window.didomiOnReady` is an array that will be populated with a function when Didomi JS is
     * initialized. We resolve its `Didomi` when its ready, or assume that users rejected it if Didomi has timed out.
     */
    setTimeout(() => {
      resolve(false)
    }, DIDOMI_READY_TIMEOUT_IN_MILLISECONDS)

    didomiOnReady.push((Didomi) => {
      const userConsentWithVendorAndItsPurpose = Didomi.getUserConsentStatus(purpose, vendorId)

      resolve(userConsentWithVendorAndItsPurpose)
    })
  })
}
