import i18n from "lib/i18n"
import React from "react"

export default function PrintButton() {
  const onClick = (event) => {
    event.preventDefault()
    window.print()
  }

  return (
    <div className="print">
      <a href="#" onClick={onClick}>
        <i className="icon-print" /> {i18n.t("content.button.print")}
      </a>
    </div>
  )
}
