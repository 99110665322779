const HIDE_ANIMATION_TIMEOUT = 500 // in milliseconds
const REMOVE_TIMEOUT = 5000 // in milliseconds
const SHOW_ANIMATION_TIMEOUT = 25 // in milliseconds

class FlashObject {
  static parse(node) {
    const flash = new FlashObject({})
    flash.body = node
    flash.body.addEventListener("click", () => flash.remove())
    flash.startRemoveTimeout()
  }

  constructor(attrs) {
    const options = Object.assign(
      {
        type: "notice",
      },
      attrs,
    )

    this.body = document.createElement("p")
    this.body.classList.add("flash", "hidden", options.type)
    this.body.textContent = options.message
    this.body.addEventListener("click", () => this.remove())
  }

  show() {
    document.body.appendChild(this.body)

    setTimeout(() => {
      this.body.classList.add("show")
      this.body.classList.remove("hidden")
    }, SHOW_ANIMATION_TIMEOUT)

    this.startRemoveTimeout()
  }

  startRemoveTimeout() {
    setTimeout(this.remove.bind(this), REMOVE_TIMEOUT)
  }

  remove() {
    this.body.classList.remove("show")
    this.body.classList.add("hidden")

    setTimeout(() => this.body.remove(), HIDE_ANIMATION_TIMEOUT)
  }
}

class Flash {
  static bindServerFlash() {
    Array.from(document.querySelectorAll("p.flash")).forEach((node) => FlashObject.parse(node))
  }

  static now(type, message) {
    new FlashObject({ type, message }).show()
  }
}

export default Flash
