import $ from "jquery"
import { View } from "backbone"
/* eslint-disable
    camelcase,
    class-methods-use-this,
    consistent-return,
    func-names,
    no-multi-assign,
    no-shadow,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
let SocialButtonView
export default SocialButtonView = (function () {
  let minimise_number
  SocialButtonView = class SocialButtonView extends View {
    static initClass() {
      minimise_number = function (num) {
        if (num < 1000) {
          return num
        }
        return `${Math.round(num / 100) / 10}k`
      }
    }
    initialize(options) {
      const button = $(options.el)[0]

      if (options.count && options.count !== 0) {
        const countEl = $('<span class="data-count" />').text(minimise_number(options.count))

        $(button).append(countEl)
      }
    }
  }
  SocialButtonView.initClass()
  return SocialButtonView
})()
