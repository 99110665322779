import ErrorTrack from "lib/error_track"
import { get } from "lodash"

/**
 * Gets the current region code from the <meta name="current-region /> tag.
 * If there is no meta tag with such name, the promise is rejected.
 *
 * @return {Promise}
 */
export default function currentRegion() {
  return new Promise((resolve, reject) => {
    const currentRegionMetaTag = document.querySelector("meta[name='current-region']")
    const regionCode = get(currentRegionMetaTag, "content")

    if (regionCode === undefined) {
      ErrorTrack.notify("Couldn't set region. The page doesn't have current-region meta tag.")
      reject(new Error("no-region"))
    }

    resolve(regionCode)
  })
}
