import currentRegion from "lib/currentRegion"
import renderPromos from "promos/renderPromos"

/**
 * @param {Object} promosContext
 */
export default async function renderContentPromos(promosContext) {
  const region = await currentRegion().catch(() => "no-region")

  const {
    collaborator_ids: collaboratorIds,
    section_ids: sectionIds,
    topic_ids: topicIds,
    content_language: contentLanguage,
    user,
  } = promosContext

  const context = {
    content: {
      collaboratorIds,
      language: contentLanguage,
      sectionIds,
      topicIds,
    },
    region,
    user,
  }

  return renderPromos(context)
}
