import PropTypes from "prop-types"
import React, { Suspense } from "react"

import LazyLoad, { LazyWrapper } from "./LazyLoad"
import { eventBus, redirectTo } from "../utils"
import { trackDismiss, trackEngagement, trackImpression } from "../promo_tracking"
import utmGenerator from "../promos/utmGenerator"

const DYNAMIC_IMPORT_MAPPER = {
  ContentInline: React.lazy(() => import("./promos/ContentInline")),
  DonationDialog: React.lazy(() => import("./promos/DonationDialog")),
  EndOfArticle: React.lazy(() => import("./promos/EndOfArticle")),
  Republish: React.lazy(() => import("./promos/RepublishPanel")),
  StickyPopup: React.lazy(() => import("./promos/StickyPopup")),
}

/**
 * Handles dynamically loading the promo components.
 */
function ComponentLoader({ type, placeholder, ...props }) {
  // eslint-disable-next-line react/no-danger
  const fallback = <div dangerouslySetInnerHTML={{ __html: placeholder }} />
  const Comp = DYNAMIC_IMPORT_MAPPER[type]

  return (
    <Suspense fallback={fallback}>
      <Comp {...props} />
    </Suspense>
  )
}

ComponentLoader.propTypes = {
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
}

ComponentLoader.defaultProps = {
  placeholder: "",
}

/**
 * This component renders a promo of the given type. Several promos support lazy rendering.
 */
function Component(props) {
  if (!DYNAMIC_IMPORT_MAPPER[props.type]) {
    return null
  }

  if (
    props.type === "DonationDialog" ||
    props.type === "Republish" ||
    props.type === "StickyPopup"
  ) {
    return (
      <LazyWrapper onView={props.onView}>
        <ComponentLoader {...props} />
      </LazyWrapper>
    )
  }

  return (
    <LazyLoad onView={props.onView}>
      <ComponentLoader {...props} />
    </LazyLoad>
  )
}

Component.propTypes = {
  type: PropTypes.string.isRequired,
  onView: PropTypes.func.isRequired,
}

/**
 * The promo component renders a promo.
 */
function Promo({ promo, placeholder, onClick, onClose, onView }) {
  // Track the click event and trigger any custom events.
  const handleClick = () => {
    trackEngagement(promo)
    onClick(promo)

    if (promo.data.trigger) {
      eventBus.next({ ...promo.data.trigger })
    } else if (promo.data.href) {
      const utmCampaign = promo.campaign && promo.campaign.utmCampaign

      redirectTo(utmGenerator(promo.data.href, utmCampaign, promo.type, `promo-${promo.promoId}`))
    }
  }

  // Track the close event and notify Google Analytics.
  const handleClose = () => {
    onClose(promo)
    trackDismiss(promo)
  }

  // Track the view event and notify Google Analytics.
  const handleView = () => {
    onView(promo)
    trackImpression(promo)
  }

  return (
    <div className="promo">
      <Component
        {...promo.data}
        placeholder={placeholder}
        onClick={handleClick}
        onClose={handleClose}
        onView={handleView}
        type={promo.type}
      />
    </div>
  )
}

Promo.propTypes = {
  promo: PropTypes.shape({
    data: PropTypes.object.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  placeholder: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onView: PropTypes.func.isRequired,
}

Promo.defaultProps = {
  placeholder: "",
}

export default Promo
